<template>
  <div>
    <v-autocomplete
      v-model="personSelected"
      :items="personList"
      outlined
      dense
      return-object
      clearable
      item-text="nome_completo"
      :label="label"
      :rules="rules"
      :loading="loading"
      @change="save"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    selectedPerson: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: 'Pessoa'
    },
    rules: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      personSelected: {},
      personList: [],
      loading: true,
      isChipDialogOpen: false,
      createIccid: '',
    };
  },
  async mounted() {
    try {
      if (this.selectedPerson && Object.keys(this.selectedPerson).length > 0) {
        this.personSelected = this.selectedPerson;
      }
      await this.loadPersons();
      this.personList = this.persons.data;

      this.loading = false;
    } catch (e) {
      await this.$swal("Oops...", e.message, "error");
    }
  },
  computed: {
    ...mapState("persons", ["persons"]),
  },
  methods: {
    ...mapActions("persons", ["loadPersons"]),

    save () {
      this.$emit("save", this.personSelected);
    }
  },
  watch: {
    selectedPerson(newVal) {
      if (this.personSelected !== newVal) {
        this.personSelected = newVal;
      }
    }
  },
};
</script>
<style scoped></style>
